changeLinks();

function changeLinks() {
    const linked = document.getElementById('linked');


        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

            linked.addEventListener("click", playMarkt)

        } else {

            linked.addEventListener("click", popup)}


}

function popup (event) {
    let modal = document.getElementById("myModal");

    let span = document.getElementsByClassName("close")[0];

    modal.style.opacity = "1";
    modal.style.zIndex = "1";

    span.onclick = function() {
        modal.style.opacity = "0";
        modal.style.zIndex = "-1";

    }
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.opacity = "0";
            modal.style.zIndex = "-1";

        }
    }
}

function playMarkt () {
    window.open('https://apps.rustore.ru/app/com.alexvod.epitracker');
}