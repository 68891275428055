changeLinks();

function changeLinks() {
    const linked = document.getElementById('btnWeb');


        linked.addEventListener("click", playMarkt)
}


function playMarkt () {
    window.open('http://88.147.208.232:3454');
}